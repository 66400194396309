import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import { Link } from "gatsby"
import PageBanner from "../components/Common/PageBanner"
import CTABanner from "../components/Common/CTABanner"
import Footer from "../components/App/Footer"
import SEO from "../components/App/SEO"

import Guidelines from "../assets/pdf/1Oxygen-Home-Loans-Lending-Guidelines-Final.pdf"
import RateCard from "../assets/pdf/November/Oxygen-Complete-Rate-Card-2-Novmber-2022.pdf"
import CompleteFactSheet from "../assets/pdf/November/Oxygen-Complete-Fact-Sheet-2-November-2022.pdf"
import HomeLoans from "../assets/pdf/4Oxygen-Home-Loans-Broker-Info-Final.pdf"
import CustomerBrochure from "../assets/pdf/5Oxygen-Customer-Brochure-Final.pdf"
import PostCodes from "../assets/pdf/6Oxygen-Post-Codes-Final.pdf"
import SMSFRate from "../assets/pdf/November/Oxygen-SMSF-Rate-Card-2-November-2022.pdf"
import SMSFFact from "../assets/pdf/November/Oxygen-SMSF-Fact-Sheet-2-November-2022.pdf"



const Terms = () => {
  return (
    <Layout>
      <SEO title="Broker Information | Oxygen - Broker Information Only" />
      <Navbar />
      <PageBanner
        pageTitle="Broker Information Only"
        pageSubTitle=""
      />
      <section className="pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="terms-of-service-content pt-3">
                <h3 className="pt-lg-3 pt-md-0">Documents</h3>
                <ul>
                  <li>
                    <a href={Guidelines} className="underlined-link link" target="_blank">Oxygen Home Loans Lending Guidelines (PDF)</a>
                  </li>
                  <li>                    
                    <a href={RateCard} className="underlined-link link" target="_blank">Oxygen Complete Rate Card (PDF)</a>
                  </li>
                  <li>                   
                    <a href={CompleteFactSheet} className="underlined-link link" target="_blank">Oxygen Complete Fact Sheet (PDF)</a>
                  </li>
                  <li>                   
                    <a href={HomeLoans} className="underlined-link link" target="_blank">Oxygen Home Loans Broker Info (PDF)</a>
                  </li>
                  <li>                    
                    <a href={CustomerBrochure} className="underlined-link link" target="_blank">Oxygen Home Loans Customer Info (PDF)</a>
                  </li>
                  <li>                    
                    <a href={PostCodes} className="underlined-link link" target="_blank">Oxygen Post Codes (PDF)</a>
                  </li>
                  <li>                   
                    <a href={SMSFRate} className="underlined-link link" target="_blank">Oxygen SMSF Rate Card (PDF)</a>
                  </li>
                  <li>                    
                    <a href={SMSFFact} className="underlined-link link" target="_blank">Oxygen SMSF Fact Sheet (PDF)</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="terms-of-service-content pt-3">
                <h3 className="pt-lg-3 pt-md-0">Calculators</h3>
                <ul>
                  <li>
                    <Link
                      to="https://highlighter.ytml.com.au/Public/Start?guid=ed4bfddf-0058-476f-bbb3-75f5e2b0dbda"
                      className="underlined-link link"
                      target="_blank"
                    >
                      OHL Complete Serviceability
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://highlighter.ytml.com.au/Public/Start?guid=8f02e6e9-c2e0-4d53-8cdb-597c6876fb4b"
                      className="underlined-link link"
                      target="_blank"
                    >
                      OHL SMSF Serviceability
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://highlighter.ytml.com.au/Public/Start?guid=3ab69591-140a-4b66-ad4a-14ddbb2cbcf2"
                      className="underlined-link link"
                      target="_blank"
                    >
                      SMSF Easy Refinance Calculator
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://oxygen-home-loans.s3.ap-southeast-2.amazonaws.com/web-site/calculators/12+Genworth+Oxygen+LMI+Premium+Calculator+2021_V5.0.xlsm"
                      className="underlined-link link"
                      target="_blank"
                    >
                     LMI Calculator
                    </Link>
                    {/* <a href={LMICAL} className="underlined-link link" target="_blank">LMI Calculator</a> */}                  
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="terms-of-service-content pt-3">
                <h3 className="pt-lg-3 pt-md-0">Customers</h3>
                <ul>
                  <li>
                    <Link
                      to="https://online.originmms.com.au/ib/oxygen"
                      className="underlined-link link"
                      target="_blank"
                    >
                      Online banking                    
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CTABanner />
      <Footer />
    </Layout>
  )
}
export default Terms
